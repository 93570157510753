.web-list {

  &__title-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    color: white;
    margin: 0;
    text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  }
    &__link {
      background-color: rgba($color: #fff, $alpha: 0.8);
      color: black;
      margin: 0;
      text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
      text-decoration: none;
      padding: 0 60px;
      border-radius: 30px;
      justify-self: flex-end;
      box-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
      margin: 30px;
      h2 {
        margin: 10px;
      }
    }

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__grid {
    width: 50%;
    // margin-top: 20%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    @media screen and (max-width: 900px) {
      width: 80%;
    }
  }

  &__link {
    border: none;
  }

  &__image {
    width: 100%;
  }
}