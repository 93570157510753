.audio-page{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 200px;
    z-index: 20;
  }

  &__current-source {
    background-color: #f3ec78;
    background-image: linear-gradient(45deg, rgb(4, 0, 248), #ff00d4);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-weight: 600;
    font-size: 48px;
  }
  &__controller{
    display: flex;
  }
  &__icon {
    margin: 20px;
  }
}