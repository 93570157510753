.work-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title{
    font-family: "Rubik Bubbles", system-ui;
    margin: 0;
  }
  &__year {
    margin-top: 0;
  }
  
  &__image {
    width: 300px;
    height: 500px;
    background-size: cover;
    background-position: center;
    border-radius: 30px;
    cursor: pointer;
    @media screen and (max-width: 900px) {
      width: 250px;
      height: 400px;
    }
  }
}