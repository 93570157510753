.works-carousel{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .carousel-container{
    width: 100%;
    height: 100%;
    background-color: rgba($color: #ffffff, $alpha: 0.8);
    pointer-events: none;
  }
  .react-multiple-carousel__arrow{
    pointer-events: all;
  }

  &__card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: all;
  }

  &__detail-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    position: absolute;
    top:0;
    left: 0;
  }

  &__detail-close-button {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    z-index: 3;
    cursor: pointer;
  }
}