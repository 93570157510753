@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.about-section {
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  *{
    box-sizing: border-box;
  }

  p {
      font-size: 16px;
      line-height: 150%;
      @media screen and (max-width: 900px) {
        font-size: 16px;
      }
    }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 80%;
    height: 100%;
    @media screen and (max-width: 900px) {
      margin-left: 0;
      width: 100%;
    }
  }

  &__content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: all ease;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 10px 80px 20px;
    text-align: left;
    height: 100%;
    overflow-y: hidden;
  }

  &__text-wrapper {
    height: 100%;
    overflow-y: auto;
    margin: 10px;
  }

  & &__cv {
    font-size: 14px;
  }

  &__form {
    display: grid;
    grid-template-columns: 50px auto;
  }
}